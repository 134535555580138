export const passwordDescription = 'Пароль должен быть не менее 8 символов, содержать латинские заглавные и строчные буквы, цифры и специальные символы (-+.#~&?!%^()@).'

export const passwordRules =  [
  { required: true, message: 'Введите пароль', trigger: 'blur' },
  { min: 6, max:200,  message: 'Не менее 6 символов', trigger: 'blur' },
  { pattern: /[0-9]/, message: 'Должна быть хотя бы одна цифра', trigger: 'blur'},
  { pattern: /[A-Z]/, message: 'Должна быть хотя бы одна латинская буква в верхнем регистре', trigger: 'blur'},
  { pattern: /[-+.#~&?!%^@()]/, message: 'Должен быть хотя бы один спецсимвол', trigger: 'blur'},
  { pattern: /[a-zA-Z0-9]/, message: 'Пароль должен содержать хотя бы одну цифру и символ в верхнем регистре', trigger: 'blur'},
  { pattern: new RegExp(/^[^а-я]+$/, 'i'), message: 'Пароль не должен содержать кириллицу', trigger: 'blur'},
]
