











































































// Core
import { Component, PropSync, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

// Interfaces
import { FromUser, User } from '@store/users/interfaces'
import { UsersActions } from '@/store/users/types'
import { ElForm } from 'element-ui/types/form'

// Modules
const NUsers = namespace('usersModule')

import {passwordRules, passwordDescription} from '@/views/admin/views/users/utils/passwordRules.js'

@Component({
  name: 'DialogAddUser',
})
export default class DialogAddUserComponent extends Vue {
  @PropSync('visible', Boolean) private _visible!: boolean

  @NUsers.Action(UsersActions.A_CREATE_USER)
  private createUser!: (user: FromUser) => Promise<User>

  private isExpectation: boolean = false

  private form: FromUser = {
    fullName: '',
    email: '',
    phone: '',
    password: '',
    passwordConfirmation: '',
  }

  private desc = passwordDescription

  protected rules = {
    fullName: [{ required: true, message: 'Обязательное поле', trigger: 'blur' }],
    email: [
      { required: true, message: 'Обязательное поле', trigger: ['blur', 'change'] },
      { pattern: /[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}/i, message: 'Неправильный e-mail', trigger: 'blur'},
    ],
    phone: [
      { required: true, message: 'Обязательное поле', trigger: 'blur' },
      { pattern: /^[+]?[0-9][-\s.]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{2,3}[-\s.]?[0-9]{2,3}$/, message: 'Неправильный номер', trigger: 'blur'},
    ],
    password: passwordRules,
    passwordConfirmation: [
      { required: true, message: 'Обязательное поле', trigger: 'blur' },
      { validator: this.checkPasswordConfirmation },
    ],
  }

  private checkPasswordConfirmation(_rule, value, callback) {
    if (value === this.form.password) {
      callback()
    } else {
      callback(new Error('Пароли не совпадают'))
    }
  }

  private handleCreateUser() {
    const form = this.$refs.form as ElForm

    form.validate(isValid => {
      if (isValid) {
        this.isExpectation = true

        this.createUser(this.form)
          .then(() => {
            this.isExpectation = false
            this._visible = false
            this.$notify({
              title: 'Выполнено',
              message: 'Действие успешно выполнено',
              type: 'success',
            })
          })
          .catch(error => {
            this.isExpectation = false
            if(error?.response?.data?.message){
              this.$notify.error({
                title: 'Ошибка',
                message: error.response.data.message,
              })
            }
          })
      }
    })
  }

  private handleClose() {
    const form = this.$refs.form as ElForm

    form.resetFields()

    this._visible = false
  }
}
